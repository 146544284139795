import {LOCALE_ID, NgModule, Optional, SkipSelf} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

import {TokenInterceptor} from '../auth/token-interceptor';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
    exports: [
        BrowserAnimationsModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'pt-BR'},
        Title,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModulo: CoreModule) {
    if (parentModulo) {
      throw new Error('CoreModule is already loaded. Importe it in the AppModule only.');
    }
  }

 }
