import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatLineModule,
    MatListModule,
    MatNativeDateModule,
    MatOptionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import { NoRecordComponent } from './components/no-record/no-record.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

import {NgxHmCarouselModule} from 'ngx-hm-carousel';

import {WavesModule, ButtonsModule, MDBBootstrapModule} from 'angular-bootstrap-md';

import { CarouselModule } from 'ngx-bootstrap/carousel';
import {ImageZoomModule} from 'angular2-image-zoom';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { PDFExportModule } from '@progress/kendo-angular-pdf-export';


@NgModule({
    declarations: [
        NoRecordComponent
    ],
    imports: [
        MatIconModule,
        MDBBootstrapModule.forRoot(),
        CarouselModule.forRoot(),
        NgxImageZoomModule.forRoot(),
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        ButtonModule,
        DialogModule,
        ImageZoomModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatLineModule,
        MatListModule,
        MatNativeDateModule,
        MatOptionModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        NoRecordComponent,
        ReactiveFormsModule,
        CarouselModule,
        NgxHmCarouselModule,
        FontAwesomeModule,
        PDFExportModule,
    ]
})
export class SharedModule { }
