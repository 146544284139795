import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Base64 } from 'js-base64';

import { StorageKeys } from '../../storage-keys';
import { User } from '../models/user.model';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

    authUser: User;
    redirectUrl: string;
    keepSigned: boolean;
    rememberMe: boolean;

    private _isAuthenticated = new ReplaySubject<boolean>(1);

    constructor(
        private  httpClient:  HttpClient,
        private router: Router
    ) {
        this._isAuthenticated.subscribe(is => console.log('AuthState', is));
        this.init();
    }

  init(): void {
    this.keepSigned = JSON.parse( window.localStorage.getItem(StorageKeys.KEEP_SIGNED) );
    this.rememberMe = JSON.parse( window.localStorage.getItem(StorageKeys.REMEMBER_ME) );
  }

  get isAuthenticated(): Observable<boolean> {
    return this._isAuthenticated.asObservable();
  }

  signinUser(variables: {email: string, password: string}): Observable<any> {

      const data = {
          grant_type: 'password',
          client_id: StorageKeys.CLIENT_ID,
          client_secret: StorageKeys.CLIENT_SECRET,
          username: variables.email,
          password: variables.password,
          scope: ''
      };

      return this.httpClient.post(`${StorageKeys.API_URL}/oauth/token`, data)
      .pipe(
        map(res => res),
        tap(res => {
          this.setAuthState( {id: res && res['id'], token: res && res['access_token'], isAuthenticated: res !== null} );
        }),
        catchError(error => {
          this.setAuthState( {id: null, token: null, isAuthenticated: false} );
          return throwError(error);
        })
      );
  }

  toggleKeepSigned(): void {
    this.keepSigned = !this.keepSigned;
    window.localStorage.setItem(StorageKeys.KEEP_SIGNED, this.keepSigned.toString());
  }

  toggleRememberMe(): void {
      this.rememberMe = !this.rememberMe;
      window.localStorage.setItem(StorageKeys.REMEMBER_ME, this.rememberMe.toString());
      if (!this.rememberMe) {
          window.localStorage.removeItem(StorageKeys.USER_EMAIL);
          window.localStorage.removeItem(StorageKeys.USER_PASSWORD);
      }
  }

  setRememberMe(user: { email: string, password: string }): void {
      if (this.rememberMe) {
          window.localStorage.setItem(StorageKeys.USER_EMAIL, Base64.encode(user.email));
          window.localStorage.setItem(StorageKeys.USER_PASSWORD, Base64.encode(user.password));
      }
  }

  getRememberMe(): { email: string, password: string } {
      if (!this.rememberMe) { return null; }
      return {
          email: Base64.decode( window.localStorage.getItem(StorageKeys.USER_EMAIL) ),
          password: Base64.decode( window.localStorage.getItem(StorageKeys.USER_PASSWORD) )
      };
  }

  logout(): void {
      window.localStorage.removeItem(StorageKeys.AUTH_TOKEN);
      window.localStorage.removeItem(StorageKeys.KEEP_SIGNED);
      this.keepSigned = false;
      this._isAuthenticated.next(false);
      this.router.navigate(['/login']);
  }

  autoLogin(): Observable<void> {
    if (!this.keepSigned) {
      this._isAuthenticated.next(false);
      window.localStorage.removeItem(StorageKeys.AUTH_TOKEN);
      return of();
    }
    /*
    return this.validateToken()
        .pipe(
            tap(authData => {
                const token = window.localStorage.getItem(StorageKeys.AUTH_TOKEN);
                this.setAuthState({id: authData.id, token, isAuthenticated: authData.isAuthenticated});
            }),
            mergeMap(res => of()),
            catchError(error => {
                this.setAuthState({id: null, token: null, isAuthenticated: false});
                return throwError(error);
            })
        );
        */
  }

  validateToken(): Observable<{ id: number, isAuthenticated: boolean }> {
      return  this.httpClient.get(`${StorageKeys.API_URL}/api/v1/auth/me`)
          .pipe(
              map(res => {
                return {
                  id: res['user'] && res['user'].id,
                  isAuthenticated: res['user'] !== null
                };
              })
          );
  }

  private setAuthState( authData: { id: number, token: string, isAuthenticated: boolean} ): void {
    /*if (authData.isAuthenticated) {
      window.localStorage.setItem(StorageKeys.AUTH_TOKEN, authData.token);
      // this.authUser = { id: authData.id };
    }*/
    window.localStorage.setItem(StorageKeys.AUTH_TOKEN, authData.token);
    this.authUser = { id: authData.id };
    this._isAuthenticated.next(authData.isAuthenticated);
  }

}
