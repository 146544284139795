import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageKeys} from '../storage-keys';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = JSON.parse(JSON.stringify(localStorage.getItem(StorageKeys.AUTH_TOKEN)));

        req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            }
        });

        return next.handle(req);
    }
}
