export class StorageKeys {
  static KEEP_SIGNED = 'vt-keep-signed';
  static AUTH_TOKEN = 'vt-auth-token';

  static API_URL  =  'http://vt-api.cipolatti.com.br:9000'; // 'http://www.cipolatti.com.br/api';
  static CLIENT_ID = 3;
  static CLIENT_SECRET = '565aHNcFgOuy2IiomTwfiZJqh5TNd8GQtKVy3w8V';

  static REMEMBER_ME = 'vt-remember-me';
  static USER_EMAIL = 'vt-user-email';
  static USER_PASSWORD = 'vt-user-password';

}
